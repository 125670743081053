<template>
  <v-app class="exception">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <div class="text-md-center">
          <h1>OOPS!</h1>
          <h2 class="my-3 headline">
            Sorry! Safemod is not yet supported on mobile devices. Please access
            this using a desktop or laptop.
          </h2>
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {};
</script>
<style scoped lang="css">
h1 {
  font-size: 130px;
  line-height: 130px;
  font-weight: 600;
  color: #252932;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
    rgba(61, 61, 61, 0.3) 3px 3px;
}
h2 {
  width: 100%;
  justify-content: center;
  text-align: center;
}
</style>
